<template>
  <v-icon
    v-text="icon"
    :color="color"
    x-small
  />
</template>
<script>
export default {
  computed: {
    color () {
      const v = this.value * (this.reverse ? -1 : 1)
      if (v < -0.02) { return 'red' }
      if (v > 0.02) { return 'green' }
      return ''
    },
    icon () {
      if (this.value < -0.02) { return this.$icon('i.ArrowDown') }
      if (this.value > 0.02) { return this.$icon('i.ArrowUp') }
      return ''
    }
  },
  props: {
    reverse: Boolean,
    value: Number
  }
}
</script>
